<template>
  <div id="section-content" class="container container-fluid">
    <div class="bar-back">
      <router-link to="/member/main"
        ><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link
      >
    </div>
    <div
      class="p-2 w-100 bg-light main-content align-self-stretch"
      style="min-height: calc((100vh - 179px) - 50px)"
    >
      <div
        class="
          bgwhitealpha
          text-secondary
          shadow-sm
          rounded
          p-2
          px-2
          xtarget
          col-lotto
          d-flex
          flex-row
          justify-content-between
          mb-1
          pb-0
        "
      >
        <div class="lotto-title">
          <h4><i class="fas fa-list-ol"></i> จัดการเลขชุด</h4>
        </div>
        <router-link to="/member/numbersets/add" class="btn btn-success btn-sm"
          ><i class="fas fa-plus"></i> สร้างเลขชุด</router-link
        >
      </div>
      <div
        class="
          bgwhitealpha
          text-secondary
          shadow-sm
          rounded
          py-2
          xtarget
          col-lotto
        "
      >
        <div
          id="numbersets_wrapper"
          class="dataTables_wrapper form-inline no-footer"
        >
          <div class="row">
          </div>
          <div class="row">
            <div class="col-12 col-sm-12">
              <table
                class="
                  datatable
                  table table-hover table-striped table-sm
                  dataTable
                  no-footer
                "
                id="numbersets"
                role="grid"
                aria-describedby="numbersets_info"
              >
                <thead>
                  <tr role="row">
                    <th
                      class="sorting_asc"
                      tabindex="0"
                      aria-controls="numbersets"
                      rowspan="1"
                      colspan="1"
                      aria-sort="ascending"
                      aria-label="ชื่อเลขชุด: activate to sort column descending"
                      style="width: 446px"
                    >
                      ชื่อเลขชุด
                    </th>
                    <th
                      class="datetime sorting"
                      tabindex="0"
                      aria-controls="numbersets"
                      rowspan="1"
                      colspan="1"
                      aria-label="วัน-เวลาที่สร้าง: activate to sort column ascending"
                      style="width: 71.2031px"
                    >
                      <span>วัน-เวลา</span><span>ที่สร้าง</span>
                    </th>
                    <th
                      class="option sorting"
                      tabindex="0"
                      aria-controls="numbersets"
                      rowspan="1"
                      colspan="1"
                      aria-label="จัดการ: activate to sort column ascending"
                      style="width: 43.2031px"
                    >
                      จัดการ
                    </th>
                  </tr>
                </thead>
                <tbody v-if="listSetNumber.length > 0">
                  <tr
                    role="row"
                    class="odd"
                    v-for="(item, index) in listSetNumber"
                    :key="index"
                  >
                    <td class="sorting_1">{{ item.title }}</td>
                    <td class="text-center">{{ item.date_create }}</td>
                    <td class="text-center">
                      <div class="btn-group dropleft">
                        <button
                          class="
                            btn btn-outline-secondary btn-sm
                            dropdown-toggle
                          "
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fas fa-cog"></i>
                        </button>
                        <div
                          class="dropdown-menu"
                          x-placement="left-start"
                          style="
                            position: absolute;
                            transform: translate3d(-162px, 0px, 0px);
                            top: 0px;
                            left: 0px;
                            will-change: transform;
                          "
                        >
                          <router-link
                            class="dropdown-item"
                            :to="`/member/numbersets/detail/${item.id}`"
                            >รายละเอียด</router-link
                          >
                          <div class="dropdown-divider"></div>
                          <button
                            class="dropdown-item"
                            @click="deleteSet(item.id)"
                            ><i class="fas fa-trash-alt"></i> ลบ</button
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr class="odd">
                    <td valign="top" colspan="3" class="dataTables_empty">
                      No data available in table
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNumberSet, deleteNumberSet } from '@/services'
import { mapActions } from 'vuex'
export default {
  async created () {
    try {
      this.setLoading(true)
      const resp = await getNumberSet()
      if (resp.status_code === 'SUCCESS') {
        this.listSetNumber = resp.data.listSetNumber
      }
      this.setLoading(false)
    } catch (error) {
      this.setLoading(false)
    }
  },
  methods: {
    ...mapActions(['setLoading']),
    async deleteSet (id) {
      try {
        this.setLoading(true)
        const resp = await deleteNumberSet(id)
        if (resp.status_code === 'SUCCESS') {
          const resp = await getNumberSet()
          if (resp.status_code === 'SUCCESS') {
            this.listSetNumber = resp.data.listSetNumber
          }
        }
        this.setLoading(false)
      } catch (error) {
        this.setLoading(false)
      }
    }
  },
  data () {
    return {
      listSetNumber: []
    }
  }
}
</script>

<style>
</style>
